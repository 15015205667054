// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/circleci/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/home/circleci/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/home/circleci/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-portfolio-book-covers-js": () => import("/home/circleci/repo/src/pages/portfolio/book-covers.js" /* webpackChunkName: "component---src-pages-portfolio-book-covers-js" */),
  "component---src-pages-portfolio-klu-pwa-js": () => import("/home/circleci/repo/src/pages/portfolio/klu-pwa.js" /* webpackChunkName: "component---src-pages-portfolio-klu-pwa-js" */),
  "component---src-pages-portfolio-life-pharmacy-mobile-app-js": () => import("/home/circleci/repo/src/pages/portfolio/life-pharmacy-mobile-app.js" /* webpackChunkName: "component---src-pages-portfolio-life-pharmacy-mobile-app-js" */),
  "component---src-pages-portfolio-random-stuff-js": () => import("/home/circleci/repo/src/pages/portfolio/random-stuff.js" /* webpackChunkName: "component---src-pages-portfolio-random-stuff-js" */),
  "component---src-pages-portfolio-web-designs-js": () => import("/home/circleci/repo/src/pages/portfolio/web-designs.js" /* webpackChunkName: "component---src-pages-portfolio-web-designs-js" */)
}

